html, body {
    font-family: 'Poppins';
}

.mainWrapper{
    min-height: 100vh; 
    margin: 0;
    display: flex; 
    flex-direction: column;  
}

.footer {
    margin-top: auto;
    min-height: 6rem;
    background: $dark;
    .navbar-nav{
        a.nav-link{
            color: white;
            display: inline-block;
            &:hover, &:focus, &:active{
                color: $link-hover;                
            }
        }
    }
}
.mainContent{
    min-height: 2rem;
}

.dropdown-item{
    &:focus, &:active {
        background-color: $link-hover;
    }
}
.dropdown-item.active{
    background-color: $link-hover;
}

.btn, .btn-primary, .btn-secondary{
    text-transform: none;
    border: none;
}

.table{
    th, td{
     
    }
}

.fixedHeight{
    height: 2rem;
    input, .btn{
        font-size: 12px;
    }
}

.modal-dialog{
    @media screen AND (min-width: 576px){
        max-width: 768px;
    }
    @media screen AND (min-width: 992px) {
        max-width: 992px;
    }
}

.text-bold{
    font-weight: 700;
}

.show-cursor{
    cursor: pointer;
}

.landingIcons{
    border-right: solid 2px $primary;
    margin-right: 1em;
}
.numeric{
    font-variant-numeric: tabular-nums;
}
.favoritesRow{
    background-color: white;
    transition: background-color 0.25s ease;
    &:hover{
        background-color: $light;
    }
}
.customerAnchor{
    color: $secondary;
    transition: color 0.25s ease;
    &:hover{
        color: $dark;
    }
}