.categoryBox{
    min-height: 6rem;
    transition: scale 100ms ease-out, background-color 100ms ease-out;
    &:hover{
        scale: 1.05;
        cursor: pointer;
    }
}
.activeBox {
    cursor: pointer;
    background-color: $link-hover;
    color: $dark;
}

.overviewTable{
    td:nth-child(4){
        display: none;
    }
}
.saleTable{
    td:nth-child(4), td:nth-child(6){
        display: none;
    }
    td:nth-child(5){
        color: red;
        font-weight: 700;
    }
}

.cartToast{
    position: fixed;
    bottom: 50%;
    right: 50%;
    background: white;
    z-index: 10000;
    border-left: solid 3px $secondary;
}