
  .small-table {
    font-size: 12px;
  }
  
  .customeradress p {
    line-height: 0.5rem;
  }
  
  .box-border-left{
    border-left: solid 0.5rem $secondary;
  }

  .fullpage-background{
    background: url('/images/signin_background.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .action-required{
    border-left: solid 0.5rem red;
  }

  .otpInput{
    input {
      width: 2.5rem !important;
      height: 2.5rem;
      margin: 0 0.2rem;
      font-size: 1rem;
      border-radius: 4px;
      border: 1px solid rgba(0,0,0,0.3);
    }
  }

  .offerButton{
    &:hover, &:focus, &:active{
      text-decoration: none !important;
      box-shadow: none;
    }
    div{
      color: black;
      text-decoration: none;
      &:hover, &:focus, &:active{
        text-decoration: none !important;
      }
    }
  }

  .custom-link{
    &:hover{
      text-decoration: none;
    }
  }

  .repeatOfferBadge{
   font-size: small;
   padding: 0.5em;
    &:hover{
      cursor: pointer;
    }
  }