

$primary: #1E41BB;
$secondary: #1883AF;
$link-hover: #6EC1E4;
$dark: #000B31;
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import "./nav";
@import "./custom";
@import "./landing";
@import "./fonts";
@import "./global";
