.no-link-ui {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.main-nav {
  .navbar-toggler {

  }

  a.navbar-brand {

    &:hover {

    }
  }

  #basic-navbar-nav {
    a.nav-link {
      color: black;
      display: inline-block;

      &:hover {
        color: $link-hover;
      }

      @media all and (min-width: 992px) {
        &:hover {
          color: $link-hover;
          text-decoration: none;
        }
      }
    }

    .dropdown-item {
      a.nav-link {
        &:hover {
          color: black;
        }
      }
    }

  }

  .navbar-nav {
    @media all and (min-width: 992px) {

    }
  }
}

#language-nav-dropdown {

}

div[aria-labelledby='language-nav-dropdown'] {
  min-width: auto;
}

.footer{
  .navbar-nav{
    a{
      &.active{
        color: $secondary !important;
      }
    }
  }
}