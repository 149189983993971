@font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Light.ttf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Medium.ttf");
    font-style: medium;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "Poppins";
    src: url("/fonts/Poppins-Bold.ttf");
    font-style: bold;
    font-weight: 700;
    font-display: swap;
  }